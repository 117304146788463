<template>
  <v-container>
    <v-row>
      <slot></slot>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash';

export default {
  provide() {
    return {
      nForm: this,
    };
  },
  data: function () {
    return {
      fields: [],
    };
  },
  methods: {
    validate() {
      let promises = this.fields.map((item) => {
        return item.validate();
      });

      return Promise.all(promises);
    },
    register(field) {
      //console.log(`[INFO] Registering input '${field._uid}'`);
      this.fields.push(field);
    },
    reset() {
      this.fields.map((f) => {
        f.reset();
      });
    },
    unregister(f) {
      //console.log(`[INFO] Unregistering input '${f}'`);
      _.remove(this.fields, (el) => {
        return el._uid == f;
      });
    },
  },
};
</script>
