<script>
import _ from 'lodash';

export default {
  inject: {
    nForm: {
      default: null,
    },
  },
  // inject: {
  //   nform: {
  //     default: null,
  //   },
  //   tabError: {
  //     default: () => {},
  //   },
  //   tab: {
  //     default: null,
  //   },
  // },
  props: {
    rules: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      errors: [],
    };
  },
  created() {
    this.nForm && this.nForm.register(this);
  },
  beforeDestroy() {
    this.nForm && this.nForm.unregister(this._uid);
  },
  methods: {
    reset() {
      this.errors = [];
      this.$emit("input", null);
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.errors = [];

        let promises = this.rules.map((rule) => {
          if(!this.disabled){
            // OBTIENE EL VALOR DE LA REGLA
            let func = rule(this.internalValue);

            // VERIFICA SI LA REGLA ES UNA PROMESA
            let isPromise = this.isPromise(func);

            // SE VALIDA LA PROMESA
            if(isPromise){
              // SE RETORNA LA PROMESA DIRECTAMENTE
              return func;
            } else {

              // SE CREA UNA NUEVA PROMESA
              return new Promise((res, rej)=>{
                if(func == true){
                  res(true);
                }else{
                  rej(func);
                }
              });
            }
          }
        });

        Promise.all(promises)
          .then(() => {
            this.errors = [];
            resolve();
          })
          .catch((err) => {
            this.errors = [ err ];
            reject(err);
          });
      });
    },
    isPromise(p) {
      if (typeof p === 'object' && typeof p.then === 'function') {
        return true;
      }

      return false;
    }
    // validate() {
    //   return new Promise((resolve, reject) => {
    //     // SE REINICIA LA LISTA DE ERRORES
    //     this.errors = [];

    //     // SE CREA UNA NUEVA LISTA DE PROMESAS
    //     let promises = [];

    //     // SE AGREGAN TODAS LAS VALIDACIONES A LA LISTA DE PROMESAS
    //     this.rules.map((rule) => {
    //       promises.push(rule(this.selected));
    //     });

    //     // SE EJECUTA LA LISTA DE PROMESAS
    //     Promise.all(promises)
    //       .then(() => {
    //         // SI TODAS RESULTARON EXITOSAS SE RESUELVE LA PROMESA
    //         resolve();
    //       })
    //       .catch((err) => {
    //         // EN CASO DE ERROR SE AGREGA EL MENSAJE A LA LISTA DE ERRORES
    //         this.errors.push(err);

    //         // SE OBTIENE EL TAB AL QUE PERTENECE EL INPUT CON ERRORES
    //         let s = _.get(this, "tab.$vnode.data.slot");

    //         // SI COMPRUEBA QUE EXISTA EL TAB
    //         if (s) {
    //           // SE ENVIA AL TAB CON ERRORES
    //           this.tabError(s);
    //         }

    //         // SE RECHAZA LA PROMESA
    //         reject();
    //       });
    //   });
    // },
  },
};
</script>