<template>
  <v-fade-transition mode="out-in">
    <router-view v-if="loaded" />
  </v-fade-transition>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      loaded: false,
    };
  },
  mounted() {
    // SE VERIFICA SI EXISTE UNA SESION ACTIVA
    this.$store
      .dispatch("authentication/authenticate", {})
      .then(() => {
        // SE OBTIENEN LOS ELEMENTOS DEL CARRITO DE COMPRAS DEL USUARIO
        this.$store.dispatch("cart/getCart");

        // HABILITA EL ROUTER PARA CONTINUAR CON LA NAVEGACION
        this.loaded = true;
      })
      .catch(() => {
        // SOLO SE HABILITA EL ROUTER PARA CONTINUAR CON LA NAVEGACION
        this.loaded = true;
      });
  },
};
</script>