<template>
  <v-container class="">
    <v-row>
      <v-col cols="12" md="12">
        <v-card :loading="loading">
          <v-date-picker
            header-color="primary"
            flat
            full-width
            no-title
            v-model="selected.day"
            range
            :allowed-dates="allowedDates"
            :disabled="!enableDateSelection"
            @update:picker-date="(e) => fetchDays(e)"
            @input="(e) => fetchHours(e)"
          ></v-date-picker>
        </v-card>
      </v-col>

      <v-col cols="12" md="12">
        <v-card :disabled="loading">
          <v-card-subtitle>HOUR</v-card-subtitle>

          <v-card-text>
            <v-chip-group
                active-class="primary--text"
                column
                v-model="selected.hour"
              >
                <v-chip
                  v-for="hour in hours"
                  :key="`h-${hour}`"
                  @click="
                    () => {
                      selected.hour = hour;
                      fetchMinutes(selected.day, hour);
                      enableDateSelection = false; //BANDERA PARA BLOQUEAR EL CALENDARIO AL ELEGIR HORA PARA UNA PRIMERA FECHA SELECCIONADA
                    }
                  "
                  :value="Number(hour)"
                >
                  {{ hour }}:00
                </v-chip>
              </v-chip-group>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="12">
        <v-card :disabled="loading">
          <v-card-subtitle>MINUTE</v-card-subtitle>
        
          <v-card-text>
            <v-chip-group
              active-class="primary--text"
              column
              v-model="selected.minute"
            >
              <v-chip
                v-for="minute in minutes"
                :key="`m-${minute}`"
                @click="
                  () => {
                    selected.minute = minute;
                    setAppointment();
                  }
                "
                :value="Number(minute)"
              >
                {{ selected.hour }}:{{ paddedNumber(minute) }}
              </v-chip>
            </v-chip-group>
          </v-card-text>

          <v-card-text class="red--text" v-if="errors && errors.length > 0">
            {{ errorMessage }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import _ from 'lodash';
import NValidatable from './NValidatable';

export default {
  mixins: [ NValidatable ],
  props: {
    daysEndpoint: {
      type: String,
      default: "api/v1/public/schedule/pln/days",
    },
    hoursEndpoint: {
      type: String,
      default: "api/v1/public/schedule/pln/hours",
    },
    minutesEndpoint: {
      type: String,
      default: "api/v1/public/schedule/pln/minutes",
    },
    commerceId: {
      type: String,
      default: null,
    },
    entityId: {
      type: String,
      required: true,
    },
    productId: {
      type: [String, Number],
      required: true,
    },
    value: {
      type: String
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
    errorMessage(){
        return _.get(this.errors, 0);
    }
  },
  data() {
    return {
      loading: false,
      days: [],
      hours: [],
      minutes: [],
      selected: {
        day: null, //moment().format("YYYY-MM-DD"),
        hour: null,
        minute: null,
      },
      enableDateSelection: true, //BANDERA PARA BLOQUEAR CALENDARIO
    };
  },
  created() {
    // OYENTE PARA EL EVENTO 'reset-calendar' EMITIDO EN NAppointmentDialog.vue
    this.$root.$on('reset-calendar', this.resetCalendar);
  },

  destroyed() {
    // ELIMINACION DEL OYENTE CUANDO EL COMPONENTE SE DESTRUYE PARA EVITAR FUGAS DE MEMORIA
    this.$root.$off('reset-calendar', this.resetCalendar);
  },
  methods: {
    fetchDays(date) {
      this.days = [];
      this.hours = [];
      this.minutes = [];

      let begin = moment(date + "-01").format("YYYY-MM-DD");
      let days = moment(date, "YYYY-MM").daysInMonth();
      let end = moment(date + "-" + days).format("YYYY-MM-DD");

      this.loading = true;

      let params = {
        fechaIni: begin,
        fechaFin: end,
        productoId: this.productId,
        entidadId: this.entityId,
      };

      if (!_.isEmpty(this.commerceId)) {
        params.comercioId = this.commerceId;
      }

      let query = new URLSearchParams(params);

      axios
        .get(`${this.daysEndpoint}?${query.toString()}`)
        .then((res) => {
          let days = _.get(res, "data.data", []);

          this.days = days;
        })
        .catch((err) => {
          this.$console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchHours(date) {
      this.loading = true;
      this.hours = [];
      this.minutes = [];

      let params = {
        fecha: date,
        productoId: this.productId,
        entidadId: this.entityId,
      };

      if (!_.isEmpty(this.commerceId)) {
        params.comercioId = this.commerceId;
      }

      let query = new URLSearchParams(params);

      if (this.selected.day.length > 1 && this.selected.hour == null) {
          /*SI NO HAY HORA SELECCIONADA PARA LA PRIMERA FECHA SELECCIONADA Y SE DA CLICK A 
          OTRA FECHA, ENTONCES ESTA SE TOMA COMO LA PRIMERA FECHA*/
          let switchDate = this.selected.day[1];
          this.selected = {
            day: switchDate,
            hour: null,
            minute: null,
          };
        }

      axios
        .get(`${this.hoursEndpoint}?${query.toString()}`)
        .then((res) => {
          let hours = _.get(res, "data.data", []);

          this.hours = hours;
        })
        .catch((err) => {
          this.$console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
      if(this.selected.day.length > 1 && this.selected.hour != null && this.selected.minute != null){
        this.selected.hour = '00';
        this.selected.minute = '00';
        this.setAppointment();
      }else if (this.selected.day.length == 1 && this.selected.hour != null && this.selected.minute != null){
        /*AL HACER OTRO CICLO DE ELECCION DE FECHAS Y ELEGIR LA PRIMERA SE SETEA LA CADENA VACIA
        DE LA VARIABLE internalValue PARA QUE LA VALIDACION DEL CALENDARIO NO SE PIERDA*/
        this.internalValue = String();

        /*SE SETEAN A NULL LAS VARIABLES DE HORA Y MINUTO PARA QUE NO SE CONSIDERE UN RANGO DE FECHAS 
        AL SOLO NAVEGAR POR LOS DIAS
         */
        this.selected.hour = null;
        this.selected.minute = null;
      }
    },
    fetchMinutes(date, hour) {
      this.loading = true;
      this.minutes = [];

      let params = {
        fecha: date,
        hour: hour,
        productoId: this.productId,
        entidadId: this.entityId,
      };

      if (!_.isEmpty(this.commerceId)) {
        params.comercioId = this.commerceId;
      }

      let query = new URLSearchParams(params);

      axios
        .get(`${this.minutesEndpoint}?${query.toString()}`)
        .then((res) => {
          let minutes = _.get(res, "data.data", []);

          this.minutes = minutes;
        })
        .catch((err) => {
          this.$console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    paddedNumber(num) {
      return _.padStart(num, 2, "0");
    },
    allowedDates(val) {
      /*SI NO HAY FECHA SELECCIONADA O SOLO SE HA SELECCIONADO EL DIA PARA LA PRIMERA FECHA,
        HABILITAR TODAS LAS FECHAS QUE SE ENCUENTRAN EN EL ARRAY days
      */
      let index;
      if (!this.selected.day || this.selected.day.length === 1 && this.selected.hour == null && this.selected.minute == null) {
        index = _.findIndex(this.days, function (d) {
        return d == val;
        });
        return index >= 0;
      }else{
        let startDayIndex = -1;
        startDayIndex = this.days.indexOf(this.selected.day[0]);
        index = _.findIndex(this.days, function (d) {
          return d == val;
        });
        // PERMITIR LA FECHA SI SE ENCUENTRA EN EL ARRAY Y ESTA EN o DESPUES DE startDayIndex
        return index !== -1 && index >= startDayIndex;
      }
    },
    allowedHours(val) {
      let index = _.findIndex(this.hours, function (h) {
        return h == val;
      });

      return index >= 0;
    },
    allowedMinutes(val) {
      let index = _.findIndex(this.minutes, function (m) {
        return m == val;
      });

      return index >= 0;
    },
    setAppointment() {
      let day = this.selected.day;
      let hour = this.selected.hour;
      let minute = this.selected.minute;

      this.internalValue = `${day} ${hour}:${minute}`;

      //SE EMITE EVENTO DE LA FECHA SELECCIONADA, EL CUAL ES ESCUCHADO EN n-appointment DE NAppointmentDialog
      this.$emit('date-selected',day,hour,minute);
      //SE ACTUALIZA EL ESTADO DE LA BANDERA QUE BLOQUEA EL CALENDARIO
      this.enableDateSelection = true;
      //SE LIMPIAN LAS VARIABLES DE HORA Y MINUTO
      this.$emit('days-updated', this.days);
      //this.selected.hour = null;
      //this.selected.minute = null;
    },
    resetCalendar(){
      //SE LIMPA EL OBJETO PARA QUE NO GUARDE LA SELECCION DE LA FECHA SELECCIONADA
      this.selected= {
        day: null,
        hour: null,
        minute: null,
      };
      //SE LIMPIAN LOS ARREGLOS DE HORA Y MINUTOS
      this.hours = [];
      this.minutes = [];
      //LA BANDERA DE BLOQUEO DE CALENDARIO SE REGRESA A SU ESTADO INICIAL
      this.enableDateSelection = true;
    }
  },
};
</script>