import _ from 'lodash'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    items: [],
  },
  mutations: {
    setItems: (state, payload) => {
      state.items = payload;
    }
  },
  actions: {
    updateCart: (ctx, items) => {
      return new Promise((resolve) => {

        ctx.commit('setItems', items);

        resolve();
      });
    },
    getCart: (ctx) => {
      return new Promise((resolve, reject) => {
        axios.get('api/v1/cart').then((res) => {
          const items = _.get(res, 'data.data', []);

          ctx.commit('setItems', items);

          resolve();
        }).catch((err) => {

          reject(err);
        });
      });
    }
  }
}