<template>
  <v-alert
    :icon="icon"
    :color="color"
    border="left"
    dark
    :dismissible="dismissible"
    :style="{
      'position': 'fixed',
      'bottom': `${position}px`,
      'left': '50%',
      'transform': 'translate(-50%, 0)',
      'z-index': 9999999999999999999999
    }"
  >
    <span class="font-weight-black">
      {{ text }}
    </span>
  </v-alert>
</template>

<script>
import vuetify from "../../plugins/vuetify";

export default {
  vuetify,
  props: {
    icon: {
      type: String,
      default: "mdi-check",
    },
    text: {
      type: String,
      default: "notification content!",
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "#212121",
    },
    position: {
      type: Number,
      default: 0,
    },

  },
};
</script>