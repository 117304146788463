import _ from 'lodash';
import db from '../../plugins/dexie';

export default {
  namespaced: true,
  state: {
    items: [],
  },
  mutations: {
    setItems: (state, payload) => {
      state.items = payload;
    }
  },
  getters: {
    appointmentsCount: (state) => {
      return _.sumBy(state.items, function (a) {
        if (a.esAgendable === 'Y') {
          return 1;
        } else {
          return 0;
        }
      });
    },
    appointments: (state) => {
      return state.items.filter((a) => a.esAgendable === 'Y');
    },
    shippingCount: (state) => {
      return _.sumBy(state.items, function (a) {
        if (a.esAgendable === 'N') {
          return 1;
        } else {
          return 0;
        }
      });
    },
  },
  actions: {
    clearCart: (ctx) => {
      return new Promise((resolve, reject) => {
        db.cart.clear()
        .then(() => {
          return db.cart.toArray();
        })
        .then((items) => {
          ctx.commit('setItems', items);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    getCart: (ctx) => {
      return db.cart.toArray()
        .then((items) => {
          ctx.commit('setItems', items);
        })
        .catch((err) => {
          ctx.commit('setItems', []);
          reject(err);
        });
    },
    increaseQuantity: (ctx, item) => {
      return new Promise((resolve, reject) => {
        db.cart.where({
          'prdId': item.prdId,
          'tblCnfComercio.comercioId': _.get(item, 'tblCnfComercio.comercioId')
        }).first()
        .then((result) => {
          if(result){
            return db.cart.update(item.ocDtlId, { ...item, cantidadCaja: result.cantidadCaja + 1 });
          }else{
            return db.cart.add(item);
          }
        })
        .then(() => {
          return db.cart.toArray();
        })
        .then((items) => {
          ctx.commit('setItems', items);
        })
        .catch((err) => {
          reject(err);
        });
      });
    },
    removeItem: (ctx, item) => {
      return new Promise((resolve, reject) => {
        db.cart.delete(item.ocDtlId)
        .then(() => {
          return db.cart.toArray();
        })
        .then((items) => {
          ctx.commit('setItems', items);
        })
        .catch((err) => {
          reject(err);
        });
      });
    },
  },
}
