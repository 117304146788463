<template>
    <v-dialog :width="width" persistent v-model="visible">
        <v-card :color="color">
            <v-card-title>{{ title }}</v-card-title>
            <v-card-text v-html="text"></v-card-text>
            <v-card-text v-if="prompt">
                <v-text-field
                    :type="type"
                    v-model="promptText"
                ></v-text-field>
            </v-card-text>
            <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn icon @click="()=>$emit('ok', promptText)">
                    <v-icon>mdi-check</v-icon>
                </v-btn>
                <v-btn icon @click="()=>$emit('cancel')" v-if="confirmation">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import vuetify from '../../plugins/vuetify';

export default {
    vuetify,
    props: {
        width: {
            type: [String, Number],
            default: '300px',
        },
        text: {
            type: String,
            default: 'dialog content!',
        },
        title: {
            type: String,
            default: 'NDialogPlugin',
        },
        color: {
            type: String,
            default: '#FFFFFF',
        },
        confirmation: {
            type: Boolean,
            default: false,
        },
        prompt: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'text',
        }
    },
    data(){
        return{
            visible: true,
            promptText: '',
        }
    },
}
</script>