import Vue from 'vue'
import Router from 'vue-router'
import _ from 'lodash';

Vue.use(Router)

const router = new Router({
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    // FUNCION DE REDIRECCIONAMIENTO CON BASE EN LA ENTIDAD EN LAS VARIABLES DE ENTORNO
    {
      path: '/',
      redirect: to => {
        // OBTIENE LA ENTIDAD DE LAS VARIABLES DE ENTORNO
        const entidadId = process.env.VUE_APP_ENTIDAD;

        // VERIFICA SI EXISTE UNA ENTIDAD
        if (_.isEmpty(entidadId)) {

          // SI NO EXISTE UNA ENTIDAD, SE REDIRIJE A LA PANTALLA DE SELECCION DE ENTIDADES
          return { path: '/entities' };

        } else {
          
          // SI EXISTE UNA ENTIDAD, SE REDIRIJE A LA PANTALLA PRINCIPAL DE PRODUCTOS CON LA ENTIDAD_ID COMO PARAMETRO
          return { path: '/entities/:entidadId', params: { entidadId } }  
        }
        
      },
    },
    // SELECCION DE ENTIDADES
    {
      path: '/entities',
      component: () => import('@/layouts/simple/Index.vue'),
      children: [
        {
          path: '/',
          component: () => import('@/views/home/Entities.vue'),
        },
        {
          path: ':entidadId/stores',
          component: () => import('@/views/home/Entities.vue'),
        },
        {
          path: ':entidadId/guest',
          component: () => import('@/views/shop/GuestCheckout.vue'),
        },
      ]
    },
    // PANTALLA PRINCIPAL DE PRODUCTOS DE LA ENTIDAD
    {
      path: '/entities/:entidadId',
      component: () => import('@/layouts/base/Index.vue'),
      children: [
        {
          path: '/',
          component: () => import('@/views/home/Home.vue'),
        },
        
        // COMPRADOR
        {
          path: 'commerce/:comercioId/checkout',
          component: () => import('@/views/shop/CommerceCheckout.vue'),
        },
        {
          path: 'cart', // UTILIZADO PARA MOSTRAR EL CARRITO EN VERSION MOVIL
          component: () => import('@/views/shop/Cart.vue'),
        },
        {
          path: 'mobile-categories',
          component: () => import('@/views/MobileCategories.vue')
        },

        // RUTAS DE GESTION DE LA CUENTA
        {
          path: 'account',
          component: () => import('@/views/account/Account.vue'),
          children: [
            {
              path: '',
              component: () => import('@/views/account/profile/ViewProfile.vue'),
            },
            {
              path: 'edit-profile',
              component: () => import('@/views/account/profile/EditProfile.vue'),
            },
            {
              path: 'orders',
              component: () => import('@/views/account/orders/OrderList.vue'),
            },
            {
              path: 'orders/:ocId',
              component: () => import('@/views/account/orders/OrderDetails.vue'),
            },
            {
              path: 'orders/:ocId/codi',
              component: () => import('@/views/shop/payment_methods/CodiMovements.vue'),
            },
            {
              path: 'orders/:ocId/codi/:idc',
              component: () => import('@/views/shop/payment_methods/CodiOperations.vue'),
            },
            {
              path: 'wishlist',
              component: () => import('@/views/account/wishlist/Wishlist.vue'),
            },
            {
              path: 'addresses',
              component: () => import('@/views/account/addresses/ListAddress.vue'),
            },
            {
              path: 'addresses/:ubicacionId',
              component: () => import('@/views/account/addresses/AddAddress.vue'),
            },
            {
              path: 'add-address',
              component: () => import('@/views/account/addresses/AddAddress.vue'),
            },
            {
              path: 'contacts',
              component: () => import('@/views/account/contacts/ListContact.vue'),
            },
            {
              path: 'contacts/:cuentaContactoId',
              component: () => import('@/views/account/contacts/AddContact.vue'),
            },
            {
              path: 'add-contact',
              component: () => import('@/views/account/contacts/AddContact.vue'),
            },
          ]
        },
      ]
    },
    // RUTAS DE AUTENTICACION CON TEMPLATE BASICO
    {
      path: '/entities/:entidadId',
      component: () => import('@/layouts/base/Basic.vue'),
      children: [
        {
          path: 'recovery',
          component: () => import('@/layouts/base/Recovery.vue')
        },
        {
          path: 'signup',
          component: () => import('@/layouts/base/Signup.vue')
        },
        {
          path: 'signin',
          component: () => import('@/layouts/base/Signin.vue')
        },
      ]
    },
    {
      path: '/orders',
      component: () => import('@/layouts/simple/Index.vue'),
      children: [
        {
          path: '',
          component: () => import('@/views/shop/Completed.vue'),
        },
        {
          path: 'billing',
          component: () => import('@/views/billing/PublicBilling.vue'),
        },
        {
          path: ':ocId/checkout',
          component: () => import('@/views/shop/SimpleCheckout.vue'),
        },
        {
          path: ':ocId',
          component: () => import('@/views/account/orders/OrderDetails.vue'),
        },
        {
          path: ':ocId/codi',
          component: () => import('@/views/shop/payment_methods/CodiMovements.vue'),
        },
        {
          path: ':ocId/codi/:idc',
          component: () => import('@/views/shop/payment_methods/CodiOperations.vue'),
        },
        {
          path: ':ocId/payments',
          component: () => import('@/views/shop/Payments.vue'),
          children: [
            {
              path: '',
              component: () => import('@/views/shop/payment_methods/Index.vue'),
            },
            {
              path: '903',
              component: () => import('@/views/shop/payment_methods/Cash.vue'),
            },
            {
              path: '904',
              component: () => import('@/views/shop/payment_methods/Codi.vue'),
            },
            {
              path: '905',
              component: () => import('@/views/shop/payment_methods/Mercadopago.vue'),
            },
            {
              path: '906',
              component: () => import('@/views/shop/payment_methods/Paypal.vue'),
            },
            {
              path: '911',
              component: () => import('@/views/shop/payment_methods/Account.vue'),
            },
            {
              path: '912',
              component: () => import('@/views/shop/payment_methods/Mitec.vue'),
            },
          ]
        },
      ]
    },
  ],
})

export default router