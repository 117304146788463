<template>
  <v-col cols="12" :sm="colsSm" :md="cols">
    <v-textarea
      v-model="internalValue"
      :rows="rows"
      :placeholder="placeholder"
      :label="label"
      :prepend-inner-icon="prependInnerIcon"
      :outlined="outlined"
      :hide-details="errors.length == 0"
      :error-messages="errors"
      :clearable="clearable"
    ></v-textarea>
  </v-col>
</template>

<script>
import NValidatable from "./NValidatable";

export default {
  mixins: [NValidatable],
  props: {
    value: [String, Number],
    type: {
      type: String,
      default: "text",
    },
    cols: {
      type: String,
      default: "12",
    },
    colsSm: {
      type: String,
      default: "12",
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "NTextarea",
    },
    prependInnerIcon: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    rows: {
      type: String,
      default: "3",
    },
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  }
};
</script>