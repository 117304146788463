import Vue from 'vue'
import moment from 'moment';

Vue.filter('toDate', (value) => {
    if (value) {
        return moment.unix(value / 1000).format('DD/MM/YYYY HH:mm:ss');
    } 
});

Vue.filter('currency', (value, format) => {
    format = format || process.env.VUE_APP_CURRENCY_FORMAT || "en-US";
    
    let amount = Number(value || 0).toFixed(2);
    let sign = amount>0?'':'-';
    let absAmount = Math.abs(amount);
    let formatted = absAmount.toLocaleString(format, { minimumFractionDigits: 2 });

    return `${sign} $ ${formatted}`;
});