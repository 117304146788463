<template>
  <v-col :cols="cols" :sm="colsSm" :md="cols">
    <v-menu
      v-model="menu"
      ref="menu"
      :close-on-content-click="closeOnContentClick"
      :return-value.sync="internalValue"
      min-width="auto"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="internalValue"
          v-on="on"
          v-bind="attrs"
          :label="label"
          :prepend-inner-icon="icon"
          :outlined="outlined"
          :hide-details="errors.length == 0"
          :error-messages="errors"
          :clearable="clearable"
          readonly
        ></v-text-field>
      </template>

      <v-date-picker
        v-model="internalValue"
        @change="(date)=>$refs.menu.save(date)"
      >
      </v-date-picker>
    </v-menu>
  </v-col>
</template>

<script>
import NValidatable from './NValidatable';

export default {
  mixins: [NValidatable],
  props: {
    value: [String],
    icon: {
      type: String,
      default: "mdi-calendar"
    },
    cols: {
      type: String,
      default: "12"
    },
    colsSm: {
      type: String,
      default: "12"
    },
    outlined: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: "NDatePicker"
    },
    closeOnContentClick: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  data() {
    return {
      menu: false
    };
  },
};
</script>