import _ from 'lodash'
import axios from 'axios'

export default {
    namespaced: true,
    state: {},
    actions: {
        addOrIncrementMany: (ctx, { ocId, items }) => {
            return new Promise((resolve, reject)=>{
                axios.post(`api/v1/public/orders/${ocId}/cart`, items).then((res)=>{
                    let items = _.get(res, 'data.data', []);

                    resolve(items);
                }).catch((err)=>{

                    reject(err);
                });
            });
        },
        increaseQuantity: (ctx, { ocId, item }) => {
            return new Promise((resolve, reject)=>{
                axios.post(`api/v1/public/orders/${ocId}/cart`, item).then((res)=>{
                    let items = _.get(res, 'data.data', []);
                    resolve(items);
                }).catch((err)=>{
                    reject(err);
                });
            });
        },
        // decreaseQuantity: (ctx, item) => {
        //     return new Promise((resolve, reject)=>{
        //         axios.put(`api/v1/public/orders/${ocId}/cart`, item).then((res)=>{
        //             let items = _.get(res, 'data.data', []);

        //             resolve(items);
        //         }).catch((err)=>{
        //             reject(err);
        //         });
        //     });
        // },
        removeItem: (ctx, { ocId, item }) => {
            return new Promise((resolve, reject)=>{
                axios.delete(`api/v1/public/orders/${ocId}/cart/${item.ocDtlId}`).then((res)=>{
                    let items = _.get(res, 'data.data', []);
                    resolve(items);
                }).catch((err)=>{
                    reject(err);
                });
            });
        }
    },
}
