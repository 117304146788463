<template>
  <v-col cols="12" :sm="colsSm" :md="cols">
    <v-autocomplete
      v-model="internalValue"
      :label="label"
      :outlined="outlined"
      :placeholder="placeholder"
      :hide-details="errors.length == 0"
      :error-messages="errors"
      :loading="loading || internalLoading"
      :items="autoCompleteItems"
      :clearable="clearable"
      :search-input.sync="location"
      :disabled="disabled"
      :filter="(item) => item"
      item-text="address"
      return-object
      @click:clear="() => cleanAutoCompleteItems()"
      @update:search-input="(text) => waitUserTyping(text)"
      @change="(v) => $emit('change', v)"
    ></v-autocomplete>

    <!-- RENDERIZADO DEL MAPA -->
    <div ref="mapName" style="width: 0px; height: 0px"></div>
  </v-col>
</template>

<script>
import NValidatable from './NValidatable';
import _ from 'lodash';

export default {
  mixins: [NValidatable],
  props: {
    value: [String, Object, Number, Array, File, Date],
    label: {
      type: String,
      default: "NAddress",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    cols: {
      type: String,
      default: "12",
    },
    colsSm: {
      type: String,
      default: "12",
    },
    placeholder: {
      type: String,
      default: null,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  inheritAttrs: false,
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  data: function () {
    return {
      placesService: null,
      map: null,
      autoCompleteItems: [],
      location: "",
      timeout: null,
      internalLoading: false
    };
  },
  mounted() {
    const element = this.$refs.mapName;

    this.placesService = new window.google.maps.places.PlacesService(element);
  },
  methods: {
    waitUserTyping(query) {
      this.internalLoading = true;

      // VERIFICA SI EXISTE UN TIMEOUT
      if (this.timeout) {
        // SI YA EXISTE UN TIMEOUT SE CANCELA
        clearTimeout(this.timeout);
      }

      // SE CREA UN NUEVO TIMEOUT
      this.timeout = setTimeout(() => {
        // AL COMPLETARSE EL TIMEOUT SE EJECUTA EL SERVICIO DE BUSQUEDA
        this.autoCompleteService(query);
      }, 500);
    },
    autoCompleteService(query) {
      // SE ESTABLECEN LOS PARAMETROS PARA LA PETICION DE BUSQUEDA POR TEXTO
      let request = {
        query: query,
        type: "address",
      };

      // SE REALIZA LA CONSULTA AL SERVICIO DE BUSQUEDA DE LUGARES POR TEXTO
      this.placesService.textSearch(request, (predictions) => {
        // SE OBTIENE LAS LISTA DE RESULTADOS O SE ESTABLECE UN ARREGLO VACIO
        predictions = predictions || [];

        // SE ITERA LA LISTA DE RESULTADOS PARA OBTENER UNICAMENTE LOS CAMPOS REQUERIDOS
        predictions = predictions.map((p) => {
          return {
            address: p.formatted_address,
            lat: p.geometry.location.lat(),
            lng: p.geometry.location.lng(),
          };
        });

        // SE ESTABLECEN LOS RESULTADOS EN LA LISTA DE SELECCION
        this.autoCompleteItems = predictions;

        this.internalLoading = false;
      });
    },
    cleanAutoCompleteItems() {
      this.autoCompleteItems = [];
    }
  }
};
</script>