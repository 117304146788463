<template>
    <v-dialog v-model="visible" width="500" content-class="appointments-dialog" :persistent="true">
        
        <v-tabs-items v-model="tab" touchless>
            <v-tab-item v-for="(item, index) in internalItems" :key="index" :value="index">
                <v-card>
                    <v-card-title>{{ item.producto }}</v-card-title>

                    <v-card-text class="pa-0">
                        <n-form :ref="`appointment-${index}`">
                            <n-appointment
                                v-model="item.fechaApt"
                                :entityId="item.entidadId"
                                :productId="item.productoId"
                                :commerceId="item.tblCnfComercio && item.tblCnfComercio.comercioId"
                                :rules="[ $v.required ]"
                                @date-selected="handleDateSelected"
                                @days-updated="handleDaysUpdated"
                            ></n-appointment>
                        </n-form>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn
                            class="text-capitalize ml-auto"
                            @click="() => clean(item.productoId,item.tblCnfComercio.comercioId,item.entidadId,index)"
                        >
                            Limpiar
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="text-capitalize"
                            @click="() => close()"
                        >
                            Cancelar
                        </v-btn>
                        <v-btn
                            class="text-capitalize"
                            @click="() => validar(`appointment-${index}`)"
                        >
                            Siguiente
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
        
    </v-dialog>
</template>

<script>
import vuetify from '../../plugins/vuetify';
import NAppointment from './NAppointment.vue';
import axios from "axios";

export default {
    vuetify,
    props: {
        items: {
            type: Array,
            default: null,
        },
        finishSimpleCheckoutFlag:{
            type: Boolean,
            default: false
        }
    },
    components: {
        NAppointment
    },
    data(){
        return{
            visible: true,
            tab: 0,
            internalItems: null,
            dateRangeItems: false,
            dateRangeItemsSelected: [],
            deletedIndexes: [],
            closeFlag: false,
            days: []
        }
    },
    mounted(){
        if(this.items){
            this.internalItems = this.items;
        }
    },
    methods: {
        open(items){

            let hasServices = _.sumBy(items, function(a) {
                if(a.esAgendable === 'Y'){
                    return 1;
                }else{
                    return 0;
                }
            });
               
            if(hasServices){
                this.tab = 0;
                this.visible = true;
                this.internalItems = items || [];
            }else{
                this.finish(items);
            }
        },
        close(){
            this.clean();
            this.tab = 0;
            this.visible = false;
            this.internalItems = [];
        },
        validar(reference){
            this.$refs[reference][0].validate().then(() => {

                if(this.internalItems.length == (this.tab + 1)){
                    this.finish(this.internalItems);        
                }else{
                    this.tab ++;
                }
            })
            .catch(() => {
                // DOES NOTHING
            });
        },
        finish(items){
            if(this.dateRangeItems){
                let selected;
                this.deletedIndexes.sort((a, b) => b - a);
                this.deletedIndexes.forEach(index =>{
                    items.splice(index,1);
                });
            if(this.finishSimpleCheckoutFlag){
                selected = [...this.dateRangeItemsSelected,...items];
                //this.finishSimpleCheckoutFlag = false;
            }
            else{
                if(this.dateRangeItemsSelected.length > 0){
                    selected = [this.dateRangeItemsSelected,items];   
                }else{
                    selected = [items];
                }
            }
                this.dateRangeItems = false;
                this.$emit('ok',selected);
            }else{
                //console.log('SOLO UNA FECHA');
                //console.log('fechatApt en items', items);
                this.$emit('ok',items);
            }
            this.closeFlag = true;
            this.close();
        },
        handleDateSelected(day,hour,minute){
            //Logica para manejar la fecha
            let strDate = `${day} ${hour}:${minute}`;

            const dateArray = strDate.split(',');
            if(dateArray.length == 1){
                if(!this.internalItems[this.tab].fechaAptIni && !this.internalItems[this.tab].fechaAptFin){
                this.internalItems[this.tab].fechaAptIni = strDate;
                this.internalItems[this.tab].fechaAptFin = null;
                this.internalItems[this.tab].fechaApt = strDate;
                }else{
                    this.internalItems[this.tab].fechaAptIni = strDate;
                    this.internalItems[this.tab].fechaAptFin = null;
                    this.internalItems[this.tab].fechaApt = strDate;
                }
                    //BUSQUEDA EN RANGEITEMS DE PRODUCTOS EXISTENTES PARA SU ELIMINACION CUANDO SE ELIGE UN NUEVO RANGO EN EL CALENDARIO
                    if(this.dateRangeItemsSelected.length>0){
                    this.dateRangeItemsSelected = this.dateRangeItemsSelected.filter((item) => {
                        if(item.prdId !== this.internalItems[this.tab].prdId){
                            return true;
                        }else{
                            this.deletedIndexes.splice(this.tab,1);
                            return false;
                            } 
                        });
                        if(!this.finishSimpleCheckoutFlag){
                            //console.log('false finish simple checkout flag');
                            this.dateRangeItems = true;
                        }    
                    }
                }else if(dateArray.length > 1){
                if(this.internalItems[this.tab].fechaAptIni){
                const auxDateStartStr = this.internalItems[this.tab].fechaAptIni;
                const dateStartObject = new Date(auxDateStartStr);
                let formattedStartDate = this.formatDate(dateStartObject); 

                let dateEndObject = new Date(dateArray[1]);
                let formattedEndDate = this.formatDate(dateEndObject);

                if(dateStartObject < dateEndObject){
                    this.internalItems[this.tab].fechaAptIni = formattedStartDate;
                    this.internalItems[this.tab].fechaAptFin = formattedEndDate;
                    this.internalItems[this.tab].fechaApt =formattedStartDate+','+formattedEndDate; 
                }else if (dateStartObject > dateEndObject){
                    this.internalItems[this.tab].fechaAptIni = formattedEndDate;
                    this.internalItems[this.tab].fechaAptFin = formattedStartDate;
                    this.internalItems[this.tab].fechaApt = formattedEndDate+','+formattedStartDate;
                }else{
                    this.internalItems[this.tab].fechaAptIni = formattedStartDate;
                    this.internalItems[this.tab].fechaAptFin = formattedEndDate;
                    this.internalItems[this.tab].fechaApt =formattedStartDate+','+formattedEndDate;
                }
                const dateRange = this.getDateRange(); // Obtener el rango de fechas

            if(dateRange.length > 0){
                this.internalItems[this.tab].fechaApt =formattedStartDate;
                //BUSQUEDA EN RANGEITEMS DE PRODUCTOS EXISTENTES PARA SU ELIMINACION CUANDO SE ELIGE UN NUEVO RANGO EN EL CALENDARIO2 2 FECHAS
                this.dateRangeItemsSelected = [...this.dateRangeItemsSelected, ...dateRange.map(date => {
                return{
                    ...this.internalItems[this.tab],
                    fechaApt: date
                };
            })];

            this.deletedIndexes.push(this.tab);
            this.dateRangeItems = true;
            }else{
                console.log('SOLO UNA FECHA');
            }
            }
            }
            //ESTA LINEA POSIBLEMENTE NO SEA NECESARIA
            //this.internalItems[this.tab].fechaApt = this.internalItems[this.tab].fechaAptIni;
        },
        clean(productoId,comercioId,entidadId,index){
            if(productoId != undefined && comercioId != undefined && entidadId != undefined && index != undefined){
                //console.log('LA ACCION VIENE DEL BOTON LIMPIAR');
                // Filtrar los elementos que coincidan con los criterios y mantener solo los que no coinciden
                this.dateRangeItemsSelected = this.dateRangeItemsSelected.filter(item => {
                    return !(item.productoId === productoId && item.tblCnfComercio.comercioId === comercioId && item.entidadId === entidadId);
                });

                 //Remover el indice del arreglo deletedIndexes
                const indexToRemove = this.deletedIndexes.indexOf(index);
                if(indexToRemove !== -1){
                    this.deletedIndexes.splice(indexToRemove,1);
                }
                this.internalItems[this.tab].fechaApt = '';
                this.internalItems[this.tab].fechaAptIni = '';
                this.internalItems[this.tab].fechaAptFin = '';
            }   else if(!this.closeFlag){
                //console.log('las variables son undefined - La peticion viene de close()');
                this.deletedIndexes = [];
                this.dateRangeItemsSelected = [];

                if(this.internalItems[this.tab]?.fechaApt){
                    this.internalItems[this.tab].fechaApt = '';
                }

            }else if(this.closeFlag && this.finishSimpleCheckoutFlag){
                //console.log('finish flag no se limpia fecha apt');
                this.finishSimpleCheckoutFlag = false;
                this.closeFlag = false;
                this.deletedIndexes = [];
                this.dateRangeItemsSelected = [];
            }else if(this.closeFlag && !this.finishSimpleCheckoutFlag){
                //console.log('se limpia fechat apt viene de sesion');
                //this.internalItems[this.tab].fechaApt = '';
                this.closeFlag=false;
                this.deletedIndexes = [];
                this.dateRangeItemsSelected = [];
            }
            this.$emit('reset-calendar');
        },
        getDateRange(){
            let dateRange = [];
            let dateStrings = this.internalItems[this.tab].fechaApt.split(',');

            dateStrings.forEach(dateString => {
                const [startDateStr, endDateStr] = dateString.trim().split(',');
                const startDate = new Date(startDateStr);
                let aux = this.formatDate(startDate);
                dateRange.push(aux);

            });
            if (dateRange.length ===2) {
                const endDate = new Date(dateRange[1]); 
                // Restar un día a la última fecha del rango
                if (dateRange.length > 1) {
                    let indice = dateRange.indexOf(dateRange[1]);
                    // Si se encuentra el valor, eliminarlo usando splice
                    if (indice !== -1) {
                        dateRange.splice(indice, 1);
                    }
                }
                
                const currentDate = new Date(dateRange[0]);
                // Agregar fechas al rango hasta alcanzar la fecha de fin
                while (currentDate < endDate) {
                    currentDate.setDate(currentDate.getDate() + 1);
                    if (currentDate <= endDate) {
                        let validateDate = this.formatBasicDate(currentDate);
                        // Verificar si la fecha generada está presente en arrayDays, de ser asi se agrega
                        if (this.days.includes(validateDate)) {
                            dateRange.push(this.formatDate(currentDate));
                            }
                        
                        }
                    }
                }
            return dateRange;
        },
        handleDaysUpdated(arrayDays){
            this.days = arrayDays;
        },
        formatDate(dataObject){
            let year = dataObject.getFullYear();
            let month = dataObject.getMonth() + 1;
            let day = dataObject.getDate();
            let hours = dataObject.getHours();
            let minutes = dataObject.getMinutes();

            return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
        },
        formatBasicDate(date){
            let año = date.getFullYear();
            let mes = (date.getMonth() + 1).toString().padStart(2, '0');
            let dia = date.getDate().toString().padStart(2, '0');

            return `${año}-${mes}-${dia}`;
        }
    },
}
</script>

<style scoped>
    .appointments-dialog{
        background-color: #ff00ff;
        min-width: 400px;
        width: auto;
    }
</style>