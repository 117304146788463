import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'
import Vuex from 'vuex'
import './plugins'
import '@/assets/scss/_global.scss'
import './boot/rules'
import './boot/axios'
import VueApexCharts from 'vue-apexcharts'
import i18n from './i18n'
import _ from 'lodash'

import WSocket from './plugins/wsocket';

Vue.use(Vuex)

Vue.config.productionTip = false;

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.prototype.$console = {
    enabled: true,
    log: function (...args){
        if (this.enabled){
            console.log(`[LOG]`, ...args);
        }
    },
    info: function (...args){
        if (this.enabled){
            console.info(`[INFO]`, ...args);
        }
    },
    error: function (...args){
        if (this.enabled){
            console.error(`[ERROR]`, ...args);
        }
    }
}

Vue.prototype.$_ = _;

Vue.prototype.$socket = new WSocket({
    logs: true
});

const instance = new Vue({
    router,
    vuetify,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app');

export default instance;
