import Vue from 'vue'
import i18n from '@/i18n'
import moment from 'moment'

const syncRules = {
    required: function (e) {
        if (e !== undefined && e !== null && e !== '' && !_.isEmpty(e)) {
            return true;
        } else {
            return i18n.t('rules.required');
        }
    },
    email: function (e) {
        if (/^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(e)) {
            return true;
        } else {
            return i18n.t('rules.email');
        }
    },
    equals: function (e) {
        return (u) => {
            if (u == e) {
                return (true);
            } else {
                return (i18n.t('rules.equals'));
            }
        }
    },
    password: function (e) {
        if (e != undefined && e != null && e != '') {
            if (!/^(?=.*[a-z])/.test(e)) {
                return (i18n.t('rules.lowercase'));
            } else if (!/^(?=.*[A-Z])/.test(e)) {
                return (i18n.t('rules.uppercase'));
            } else if (!/^(?=.*[0-9])/.test(e)) {
                return (i18n.t('rules.digit'));
            } else if (!/^(?=.*[!@#$%^&])/.test(e)) {
                return (i18n.t('rules.special'));
            } else if (!/^(?=.{8,})/.test(e)) {
                return (i18n.t('rules.size'));
            } else {
                return (true);
            }
        } else {
            return (true);
        }
    },
    phone: function (e) {
        if (e && e != null && e != '') {
            if (/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(e)) {
                return (true);
            } else {
                return (i18n.t('rules.phone'));
            }
        } else {
            return (true);
        }
    },
    number: function (e) {
        if (/[+-]?\d+(\.\d+)?$/.test(e)) {
            return (true);
        } else {
            return (i18n.t('rules.number'));
        }
    },
    integer: function (e) {
        if (/^\d*$/.test(e)) {
            return (true);
        } else {
            return (i18n.t('rules.integer'));
        }
    },
    past: function (e) {
        return (f) => {
            const t = new Date(f)
            const n = new Date(e)

            if (t.getTime() < n.getTime()) {
                return true;
            } else {
                let msg = i18n.t('rules.past', [moment(e).format('YYYY-MM-DD')]);
                return msg;
            }
        }
    },
    future: function (e, mask) {
        return (f) => {
            mask = mask || "YYYY-MM-DD";

            let a = moment(f).format(mask);
            let b = moment(e).format(mask);

            let isAfter = moment(a).isAfter(b);

            if (isAfter) {
                return true;
            } else {
                let msg = i18n.t('rules.future', [b]);
                return msg;
            }
        }
    },
    futureOrEquals: function (e, mask) {
        return (f) => {
            mask = mask || "YYYY-MM-DD";

            let a = moment(f).format(mask);
            let b = moment(e).format(mask);

            let isSameOrAfter = moment(a).isSameOrAfter(b);

            if (isSameOrAfter) {
                return true;
            } else {
                let msg = i18n.t('rules.futureOrEquals', [b]);
                return msg;
            }
        }
    },
    minSize: function (e) {
        return (n) => {
            if (n.length >= e) {
                return true;
            } else {
                let msg = i18n.t('rules.min', [e]);
                return msg;
            }
        }
    },
    maxSize: function (e) {
        return (n) => {
            if (n.length <= e) {
                return true;
            } else {
                let msg = i18n.t('rules.max', [e]);
                return msg;
            }
        }
    },
    isChecked: function (isChecked) {
        if (isChecked) {
            return true;
        } else {
            return i18n.t('rules.checked');
        }
    },
};

const asyncRules = {
    // VALIDACIONES ASINCRONAS
    required(e) {
        return new Promise((resolve, reject) => {
            if (e !== undefined && e !== null && e !== '' && !_.isEmpty(e)) {
                resolve(true);
            } else {
                reject(i18n.t('rules.required'));
            }
        });
    },
    requiredIf(e) {
        return (f) => {

            return new Promise((resolve, reject) => {
                if (e && e != null && e != '') {
                    if (f != null && f != '') {
                        resolve(true);
                    } else {
                        reject(i18n.t('rules.required'));
                    }
                } else {
                    resolve(true);
                }
            });
        }
    },
    requiredIfNull(e) {
        return (f) => {
            return new Promise((resolve, reject) => {
                if (e && e != null && e != '') {
                    resolve(true);
                } else {
                    if (f != null && f != '') {
                        resolve(true);
                    } else {
                        reject(i18n.t('rules.required'));
                    }
                }
            });
        }
    },
    minSize(e) {
        return (n) => {
            return new Promise((resolve, reject) => {
                if (n && n != null && n != '') {
                    if (n.length >= e) {
                        resolve(true);
                    } else {
                        reject(i18n.t('rules.min', [e]));
                    }
                } else {
                    resolve(true);
                }
            });
        }
    },
    maxSize(e) {
        return (n) => {
            return new Promise((resolve, reject) => {
                if (n && n != null && n != '') {
                    if ((n.toString()).length <= e) {
                        resolve(true);
                    } else {
                        reject(i18n.t('rules.max', [e]));
                    }
                } else {
                    resolve(true);
                }
            });
        }
    },
    equals(e) {
        return (u) => {
            return new Promise((resolve, reject) => {
                if (u == e) {
                    resolve(true);
                } else {
                    reject(i18n.t('rules.equals'));
                }
            });
        }
    },
    phone(e) {
        return new Promise((resolve, reject) => {
            if (e && e != null && e != '') {
                if (/^([+][0-9]{1,3}[.-])?([(]{1}[0-9]{2,6}[)])?([0-9 .\-/]{3,20})((x|ext|extension)[ ]?[0-9]{1,4})?$/.test(e)) {
                    resolve(true);
                } else {
                    reject(i18n.t('rules.phone'));
                }
            } else {
                resolve(true);
            }
        });
    },
    password(e) {
        return new Promise((resolve, reject) => {
            if (e != undefined && e != null && e != '') {
                if (!/^(?=.*[a-z])/.test(e)) {
                    reject(i18n.t('rules.lowercase'));
                } else if (!/^(?=.*[A-Z])/.test(e)) {
                    reject(i18n.t('rules.uppercase'));
                } else if (!/^(?=.*[0-9])/.test(e)) {
                    reject(i18n.t('rules.digit'));
                } else if (!/^(?=.*[!@#$%^&])/.test(e)) {
                    reject(i18n.t('rules.special'));
                } else if (!/^(?=.{8,})/.test(e)) {
                    reject(i18n.t('rules.size'));
                } else {
                    resolve(true);
                }
            } else {
                resolve(true);
            }
        });
    },
    letters(e) {
        return new Promise((resolve, reject) => {
            if (e != undefined && e != null && e != '') {
                if (/^([A-Za-zÁÉÍÓÚñáéíóúÑ']+[\s]?)+$/.test(e)) {
                    resolve(true);
                } else {
                    reject(i18n.t('rules.spaces'))
                }
            } else {
                resolve(true);
            }
        })
    },
    ref(e) {
        return new Promise((resolve, reject) => {
            if (e != undefined && e != null && e != '') {
                if (/^((\d+)|((\d+)(\.\d+)+))$/.test(e)) {
                    resolve(true);
                } else {
                    reject(i18n.t('rules.reference'))
                }
            } else {
                resolve(true);
            }
        });
    },
    email(e) {
        return new Promise((resolve, reject) => {
            if (e != undefined && e != null && e != '') {
                if (/^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(e)) {
                    resolve(true);
                } else {
                    reject(i18n.t('rules.email'))
                }
            } else {
                resolve(true);
            }
        });
    },
    number(e) {
        return new Promise((resolve, reject) => {
            if (e != undefined && e != null && e != '') {
                if (/[+-]?\d+(\.\d+)?$/.test(e)) {
                    resolve(true);
                } else {
                    reject(i18n.t('rules.number'))
                }
            } else {
                resolve(true);
            }
        });
    },
    positive(e) {
        return new Promise((resolve, reject) => {
            if (e >>> 0 === parseFloat(e)) {
                resolve(true);
            } else {
                reject(i18n.t('rules.positive'))
            }

        });
    },
    integer(e) {
        return new Promise((resolve, reject) => {
            if (e != undefined && e != null && e != '') {
                if (/^\d*$/.test(e)) {
                    resolve(true);
                } else {
                    reject(i18n.t('rules.integer'))
                }
            } else {
                resolve(true);
            }
        });
    },
    json(e) {
        return new Promise((resolve, reject) => {
            if (e != undefined && e != null && e != '') {
                try {
                    let tmp = JSON.parse(e);
                    resolve(tmp);
                } catch (err) {
                    reject(i18n.t('rules.json') + ": " + err);
                }
            } else {
                resolve(true);
            }
        });
    },
    isDate(e) {
        return new Promise((resolve, reject) => {
            if (e != undefined && e != null && e != '') {
                if (/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(e)) {
                    resolve(true);
                } else {
                    reject(i18n.t('rules.date'))
                }
            } else {
                resolve(true);
            }
        });
    },
    isDatetime(e) {
        return new Promise((resolve, reject) => {
            if (e != undefined && e != null && e != '') {
                if (/^(\d{4}).(\d{2}).(\d{2}).(\d{2}).(\d{2}).(\d{2})$/.test(e)) {
                    resolve(true);
                } else {
                    reject(i18n.t('rules.date'))
                }
            } else {
                resolve(true);
            }
        });
    },
    regex(e) {
        return (r) => {
            return new Promise((resolve, reject) => {
                if (r != undefined && r != null && r != '') {
                    if (new RegExp(e).test(r)) {
                        resolve(true);
                    } else {
                        reject(i18n.t('rules.regex'))
                    }
                } else {
                    resolve(true);
                }
            });
        }
    },
    past(e) {
        return (f) => {
            return new Promise((resolve, reject) => {
                if (e != undefined && e != null && e != '') {
                    let t = new Date(f);
                    let n = new Date(e);

                    if (t.getTime() < n.getTime()) {
                        resolve(true);
                    } else {
                        reject(i18n.t('rules.past', [this._dateFormat(e)]));
                    }
                } else {
                    resolve(true);
                }
            });
        }
    },
    future(e) {
        return (f) => {
            return new Promise((resolve, reject) => {
                if (e != undefined && e != null && e != '') {
                    let t = new Date(f);
                    let n = new Date(e);

                    if (t.getTime() > n.getTime()) {
                        resolve(true);
                    } else {
                        reject(i18n.t('rules.future', [this._dateFormat(e)]));
                    }
                } else {
                    resolve(true);
                }
            });
        }
    },
    pastOrEquals(e) {
        return (f) => {
            return new Promise((resolve, reject) => {
                if (e != undefined && e != null && e != '') {
                    let t = new Date(f);
                    let n = new Date(e);

                    if (t.getTime() <= n.getTime()) {
                        resolve(true);
                    } else {
                        reject(i18n.t('rules.past', [this._dateFormat(e)]));
                    }
                } else {
                    resolve(true);
                }
            });
        }
    },
    futureOrEquals(e, mask, cb) {
        return (f) => {
            return new Promise((resolve, reject) => {

                mask = mask || "YYYY-MM-DD";

                e = e || new Date();

                cb = cb || function (x) { return x };

                f = cb(f);

                let a = moment(f).format(mask);
                let b = moment(e).format(mask);

                let isSameOrAfter = moment(a).isSameOrAfter(b);

                if (isSameOrAfter) {
                    resolve(true);
                } else {
                    reject(i18n.t('rules.future', [moment(e).format(mask)]));
                }
            });
        }
    },
    _dateFormat(value) {
        if (value) {
            return moment(String(value)).format('DD/MM/YYYY')
        }
    },
    rfc(value) {
        return new Promise((resolve, reject) => {
            if (value != undefined && value != null && value != '') {
                // patron del RFC, persona moral
                const _rfc_pattern_pm = "^(([A-ZÑ&]{3})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
                    "(([A-ZÑ&]{3})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
                    "(([A-ZÑ&]{3})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
                    "(([A-ZÑ&]{3})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";

                // patron del RFC, persona fisica
                const _rfc_pattern_pf = "^(([A-ZÑ&]{4})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
                    "(([A-ZÑ&]{4})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
                    "(([A-ZÑ&]{4})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
                    "(([A-ZÑ&]{4})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";

                if (value.match(_rfc_pattern_pm) || value.match(_rfc_pattern_pf)) {
                    resolve(true);
                } else {
                    reject("La estructura de la clave de RFC es incorrecta.");
                }
            } else {
                resolve(true);
            }
        });
    }
}
Vue.prototype.$sv = syncRules;

Vue.prototype.$v = asyncRules;