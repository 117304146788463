<template>
  <v-col :cols="cols" :sm="colsSm" :md="cols">
    <v-text-field
      v-model="internalValue"
      :type="type"
      :label="label"
      :prepend-inner-icon="prependInnerIcon"
      :outlined="outlined"
      :hide-details="errors.length == 0"
      :error-messages="errors"
      :clearable="clearable"
      :disabled="disabled"
      :placeholder="placeholder"
    ></v-text-field>
  </v-col>
</template>

<script>
import NValidatable from './NValidatable';

export default {
  mixins: [NValidatable],
  props: {
    value: [String, Number],
    type: {
      type: String,
      default: "text",
    },
    cols: {
      type: String,
      default: "12",
    },
    colsSm: {
      type: String,
      default: "12",
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "NField",
    },
    placeholder:{
      type: String,
      default:''
    },
    prependInnerIcon: {
      type: String,
      default: null,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>