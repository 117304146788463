import Vue from 'vue'
import Vuex from 'vuex'

import authentication from './authentication'
import publicCart from './cart/publicCart';
import privateCart from './cart/privateCart';
import address from './address'
import guest from './guest';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showMenuDrawer: false,
    showCartDrawer: false,
  },
  mutations: {
    switchMenuDrawer(state) {
      state.showMenuDrawer = !state.showMenuDrawer;
    },
    switchCartDrawer(state) {
      state.showCartDrawer = !state.showCartDrawer;
    },
  },
  modules: {
    authentication: authentication,
    cart: privateCart,
    publicCart: publicCart,
    address: address,
    guest: guest
  },
})
