import _ from 'lodash'

export default {
    namespaced: true,
    state: {
        address: [],
    },
    mutations: {
        addItem: (state, payload) => {
            state.address.push(payload)
        },
        removeItem: (state, payload) => {
            const i = state.address.map(item => item.addressId).indexOf(payload.addressId)
            state.address.splice(i, 1)
        },
    },
    actions: {
        removeAddress: (ctx, address) => {
            ctx.commit('removeItem', address)
        },
        addAddress: (ctx, address) => {
            ctx.commit('addItem', address)
        },
        getAddress: (ctx, addressId) => {
            return new Promise((resolve)=>{
                let address = ctx.state.address;

                let index = _.findIndex(address, (i)=>{
                    return i.addressId == addressId;
                });

                resolve(address[index]);
            });
        }
    },
}
