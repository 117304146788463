<template>
  <div class="wrapper" :data-open="state === 'open' ? 1 : 0">
    <div class="bg" @click="() => setState('half')"></div>

    <v-card
      ref="card"
      class="card"
      :elevation="1"
      :data-state="isMove ? 'move' : state"
      :style="{ top: `${isMove ? y : calcY()}px` }"
      :disabled="disabled"
    >
      <v-toolbar class="pan-area" ref="pan" flat color="primary" dark>
        <slot name="top">
          <v-spacer></v-spacer>
          <v-icon v-if="state == 'open'">{{ openIcon }}</v-icon>
          <v-icon v-else-if="state == 'half'">{{ halfIcon }}</v-icon>
          <v-icon v-else-if="state == 'close'">{{ closeIcon }}</v-icon>
          <v-spacer></v-spacer>
        </slot>
      </v-toolbar>

      <v-card-text class="contents">
        <slot></slot>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Hammer from 'hammerjs';

export default {
  props: {
    openY: {
      type: Number,
      default: 0.103,
    },
    halfY: {
      type: Number,
      default: 0.58,
    },
    defaultState: {
      type: String,
      default: "close",
    },
    halfIcon: {
      type: String,
      default: "mdi-drag-horizontal-variant",
    },
    openIcon: {
      type: String,
      default: "mdi-drag-horizontal-variant",
    },
    closeIcon: {
      type: String,
      default: "mdi-drag-horizontal-variant",
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      mc: null,
      y: 0,
      startY: 0,
      isMove: false,
      state: this.defaultState,
      rect: {},
    };
  },
  watch: {
    // disabled: {
    //   immediate: true,
    //   handler() {
    //     if(this.disabled){
    //       this.setState('half')
    //     }else{
    //       this.setState('close')
    //     }
    //   },
    // },
  },
  mounted() {
    window.onresize = () => {
      this.rect = this.$refs.card.$el.getBoundingClientRect();
    };

    this.rect = this.$refs.card.$el.getBoundingClientRect();

    this.mc = new Hammer(this.$refs.pan.$el);

    this.mc.get("pan").set({ direction: Hammer.DIRECTION_ALL });

    this.mc.on("panup pandown", (evt) => {
      this.y = evt.center.y - 16;
    });

    this.mc.on("panstart", (evt) => {
      this.startY = evt.center.y;
      this.isMove = true;
    });

    this.mc.on("panend", (evt) => {
      this.isMove = false;

      switch (this.state) {
        case "close": //Added a close state on the condition to be able to swipe from closed to half/closed state.
        case "half":
          if (this.state == "close") {
            if (this.startY - evt.center.y > 120) {
              this.state = "half";
            }

            if (this.startY - evt.center.y > 320) {
              this.state = "open";
            }

            break;
          }

          if (this.startY - evt.center.y > 120) {
            this.state = "open";
          }

          if (this.startY - evt.center.y < -50) {
            this.state = "close";
          }
          break;
        case "open":
          if (this.startY - evt.center.y < -120) {
            this.state = "half";
          }
          break;
      }
    });
  },
  beforeDestroy() {
    this.mc.destroy();
    window.onresize = null;
  },
  methods: {
    calcY() {
      // console.log(this.openY, this.halfY);
      switch (this.state) {
        case "close":
          return this.rect.height;
        case "open":
          return this.rect.height * this.openY;
        case "half":
          return this.rect.height * this.halfY;
        default:
          return this.y;
      }
    },
    setState(state) {
      this.state = state;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  z-index: 99999999999 !important;
}

.wrapper[data-open="1"] {
  z-index: 99999999999 !important;
  position: absolute;
  top: 0;
  left: 0;
}

.wrapper[data-open="1"] .bg {
  display: block;
  transition: all 0.3s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}

.card {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
}

.card[data-state="half"],
.card[data-state="open"],
.card[data-state="close"] {
  transition: top 0.3s ease-out;
}

.card[data-state="close"] {
  box-shadow: none;
}

// Moved the pan area above the card content to be ale to swipe from closed state to half/open
.card[data-state="close"] .pan-area {
  top: -55px;
}

.contents {
  overflow-y: scroll;
  max-height: 100%;
  padding-bottom: calc(100vh * 0.2);
  box-sizing: border-box;
}
</style>