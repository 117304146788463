<template>
  <v-col cols="12" :sm="colsSm" :md="cols">
    <v-autocomplete
      :label="label"
      autocomplete="off"
      :placeholder="placeholder"
      v-model="internalValue"
      :items="items"
      :item-text="getLabel"
      :item-value="getValue"
      :filter="searchFilter"
      :disabled="loading || disabled"
      :loading="loading"
      :error-messages="errors"
      :return-object="returnObject"
      :prepend-inner-icon="icon"
      :hide-details="errors.length == 0"
      :small-chips="multiple"
      :deletable-chips="multiple"
      :outlined="outlined"
      :clearable="clearable"
      :multiple="multiple"
      @change="() => onChange()"
    >
    </v-autocomplete>
  </v-col>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import NValidatable from './NValidatable';

export default {
  mixins: [NValidatable],
  props: {
    value: [String, Object, Number, Array, Boolean],
    label: {
      type: String,
      default: "NChoice",
    },
    source: {
      type: [String, Array],
      default: "",
    },
    itemText: {
      type: [String, Function],
      default: undefined,
    },
    itemValue: {
      type: [String, Function],
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    cols: {
      type: String,
      default: "12",
    },
    colsSm: {
      type: String,
      default: "12",
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    searchLimit: {
      type: Number,
      default: 5000,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    returnObject: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    preselect: {
      type: Function,
      default: null,
    },
  },
  inheritAttrs: true,
  watch: {
    source: {
      immediate: true,
      handler(newValue) {
        if (typeof this.source === "string") {
          this.getItems();
        } else {
          this.items = newValue;
        }
      },
    },
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  data() {
    return {
      items: [],
      loading: false,
    };
  },
  methods: {
    getItems() {
      // SE ACTIVA LA ANIMACION DE CARGA
      this.loading = true;

      // SE VERIFICA QUE LA URI NO SEA CADENA VACIA
      if (this.source != "") {
        // SE REALIZA LA PETICION A LA URI
        axios
          .get(this.getUri(this.source))
          .then((res) => {
            // SE OBTIENEN LOS REGISTROS DE MANERA SEGURA
            this.items = _.get(res, "data.data", []);

            // SE EJECUTA EVENTO AL OBTENER REGISTROS DESDE URI
            this.$emit("change", this.items);

            // // SE VERIFICA SI DEBEN PRESELECCIONARSE TODOS LOS ITEMS
            // if(this.preselect != null){

            //     // SE PRESELECCIONAN TODOS LOS ITEMS
            //     this.internalValue = this.preselect(this.items);
            // }

            // SE DESACTIVA LA ANIMACION DE CARGA
            this.loading = false;
          })
          .catch(() => {
            // SE PREINICIALIZA LA LISTA DE REGISTROS COMO LISTA VACIA
            this.items = [];

            // SE DESACTIVA LA ANIMACION DE CARGA
            this.loading = false;
          });
      }
    },
    searchFilter(item, queryText, itemText) {
      // SE REALIZA LA COMPARACION DEL QUERY CON LA ETIQUETA DEL ITEM
      return (
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    onChange() {
      // EMITE EL EVENTO CHANGE AL CAMBIAR EL VALOR DE LA SELECCION
      this.$emit("change", this.internalValue);
    },
    getValue(item) {
      // SE COMPRUEBA EL TIPO DE DATO DEL PARAMETRO ITEM VALUE
      if (_.isFunction(this.itemValue)) {
        // SI ES UNA FUNCION SE EJECUTA PARA OBTENER EL VALOR CORRESPONDIENTE
        return this.itemValue(item);
      } else {
        // SI NO ES UNA FUNCION SE OBTIENE EL VALOR CORRESPONDIENTE A PARTIR DEL STRING
        return _.get(item, this.itemValue);
      }
    },
    getLabel(item) {
      /* SE COMENTA LA SECCION DE SLOT YA QUE OCASIONA ERRORES AL CONTENER HTML Y TEXTO*/
      // SE VERIFICA SI EXISTE LA PROPIEDAD PARA ESTABLECER EL VALOR DEL LABEL
      // if (this.itemText === undefined) {
      //   // SI NO EXISTE SE TOMA EL VALOR DEL SLOT LABEL
      //   if (this.$scopedSlots.label !== undefined) {

      //     // SE OBTIENE EL SLOT
      //     let slot = this.$scopedSlots.label({ item: item || {} });

      //     let label = _.get(slot, "[0].text", "", item);

      //     console.log('entro a slot', label)

      //     // SE OBTIENE EL TEXTO DE MANERA SEGURA Y SE RETORNA
      //     return label;
      //   } else {
      //     // SI NO SE DEFINIO UN SLOT LABEL SE RETORNA EL OBJETO COMPLETO
      //     return item;
      //   }
      // } else {
      // SE COMPRUEBA EL TIPO DE DATO DEL PARAMETRO ITEM TEXT
      if (_.isFunction(this.itemText)) {
        // Si ES UNA FUNCION SE EJECUTA PARA OBTENER LA ETIQUETA CORRESPONDIENTE
        return this.itemText(item);
      } else {
        // SI NO ES UNA FUNCION SE GENERA LA ETIQUETA CORRESPONDIENTE A PARTIR DEL STRING
        return _.get(item, this.itemText, item);
      }
      // }
    },
    getUri(u) {
      // VERIFICA QUE LA URI TENGA PARAMETROS PREDEFINIDOS
      let arr = u.split("?");

      if (arr.length > 1) {
        // CONCATENA LOS PARAMETROS DE PAGINACION A LOS PARAMETROS PREDEFINIDOS
        return arr[0] + "?" + arr[1] + "&limit=" + this.searchLimit;
      } else {
        // UNICAMENTE ENVIA PARAMETROS DE PAGINACION
        return arr[0] + "?limit=" + this.searchLimit;
      }
    },
  },
};
</script>