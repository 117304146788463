import axios from 'axios'
import _ from 'lodash'
import instance from '@/main';

export default {
  namespaced: true,
  state: {
    accessToken: localStorage.getItem('access-token-ecommerce') || null,
    refreshToken: localStorage.getItem('refresh-token-ecommerce') || null,
    isAuthenticated: false,
  },
  getters: {
    isAuthenticated: state => !!state.accessToken,
    getProfile: state => state.profile
  },
  mutations: {
    setProfile: (state, payload) => {
      state.profile = payload;
    },
    setAccessToken: (state, payload) => {
      // SE ALMACENAN EL TOKEN DE ACCESO EN EL ALMACEN LOCAL
      localStorage.setItem('access-token-ecommerce', payload);

      state.accessToken = payload;
    },
    setRefreshToken: (state, payload) => {
      // SE ALMACENAN EL TOKEN DE ACTUALIZACION EN EL ALMACEN LOCAL
      localStorage.setItem('refresh-token-ecommerce', payload);

      state.refreshToken = payload;
    },
    setIsAuthenticated: (state, payload) => {
      state.isAuthenticated = payload;
    },
    setLogin: (state, { accessToken, refreshToken }) => {
      // SE ALMACENAN EL TOKEN DE ACCESO EN EL ALMACEN LOCAL
      localStorage.setItem('access-token-ecommerce', accessToken);

      state.accessToken = accessToken;

      // SE ALMACENAN EL TOKEN DE ACTUALIZACION EN EL ALMACEN LOCAL
      localStorage.setItem('refresh-token-ecommerce', refreshToken);

      state.refreshToken = refreshToken;

      state.isAuthenticated = true;
    },
    setLogout: (state) => {
      state.accessToken = null;
      state.refreshToken = null;

      localStorage.removeItem('access-token-ecommerce');
      localStorage.removeItem('refresh-token-ecommerce');

      state.isAuthenticated = false;
    },
  },
  actions: {
    /**
     * CONFIGURA UN INTERCEPTOR DE PETICIONES 401 Y 403 PARA CONTROL DE LA SESION
     */
    setInterceptor: (ctx) => {
      // SE RETORNA UNA NUEVA PROMESA
      return new Promise((resolve) => {

        // SE ALMACENA EL INTERCEPTOR PARA REMOVERLO EN CASO DE UNA ACTUALIZACION DE TOKEN
        axios.interceptors.response.use((response) => response,
          (error) => {
            // SE OBTIENE EL STATUS DE LA PETICION DE MANERA SEGURA
            let status = _.get(error, "response.status");

            // SE VERIFICA EL ESTATUS DE LA RESPUESTA
            if (status === 401) {

              // SE ELIMINAN TODOS LOS ESTADOS DE LA SESION
              ctx.commit('setLogout');

              // SE ENVIA AL USUARIO A LA PANTALLA DE LOGIN
              instance.$router.push('/').catch(() => {});

            } else if (status === 403) {

              // SE ENVIA AL USUARIO A LA PANTALLA DE FALTA DE PERMISOS
              instance.$router.push('/forbidden').catch(() => {});
            }

            // SE RECHAZA LA PROMESA
            return Promise.reject(error);
          }
        );

        // AL FINALIZAR LA CONFIGURACION SE RESUELVE LA PROMESA
        resolve();
      });
    },
    /**
     * ESTABLECE UNA SESION ACTIVA Y CONFIGURA EL INTERCEPTOR DE PETICIONES
     */
    authenticate: (ctx, { accessToken, refreshToken }) => {
      return new Promise((resolve, reject) => {
        try {
          // SE OBTIENE EL TOKEN DE ACCESO DEL ALMACEN LOCAL
          accessToken = accessToken || localStorage.getItem("access-token-ecommerce");

          // SE OBTIENE EL TOKEN DE ACTUALIZACION DEL ALMACEN LOCAL
          refreshToken = refreshToken || localStorage.getItem("refresh-token-ecommerce");

          // SE ESTABLECE EL TOKEN DE MANERA GLOBAL
          axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

          // SE COMPRUEBA QUE EXISTA UN TOKEN DE ACCESO
          if (accessToken != null) {

            // SE CONFIGURA EL INTERCEPTOR DE PETICIONES 401 Y 403
            ctx.dispatch('setInterceptor').then(()=>{

              // SE ESTABLECE LA SESION ACTIVA
              ctx.commit('setLogin', {
                accessToken,
                refreshToken
              });
            }).catch(()=>{
              console.error('[ERROR] SESSION => Cannot set interceptor')
            });

            // SE RESUELVE LA PROMESA
            resolve(true);
          } else {

            // SI NO EXISTE UN TOKEN SE RECHAZA LA PROMESA
            reject();
          }
        } catch (err) {

          // SE RECHAZA LA PROMESA Y SE ENVIA EL MENSAJE DE ERROR
          reject(err);
        }
      });
    },
    /**
     * ENVIA UNA PETICION PARA RESTABLECER EL PASSWORD DEL USUARIO
     */
    passwordRecovery: (ctx, username) => {
      return new Promise((resolve, reject) => {
        axios.post('api/v1/users/password', { username }).then((res) => {
          let success = _.get(res, 'data.success', false);

          if (success) {
            resolve();
          } else {
            let message = _.get(res, 'data.message', 'Hubo un error al intentar restablecer su contrasena');
            reject(message)
          }

        }).catch((err) => {
          // SE OBTIENE EL MENSAJE DE ERROR DE FORMA SEGURA
          let msg = _.get(err, 'response.data.message', err.toString());

          // SE RECHAZA LA PROMESA Y SE RETORNA EL ERROR
          reject(msg);
        });
      });
    },
    /**
     * REALIZA LA AUTENTICACION DE UN USUARIO
     */
    login: (ctx, user) => {
      return new Promise((resolve, reject) => {

        // SE ELIMINAN TOKENS BEARER PREVIOS
        delete axios.defaults.headers.common['Authorization'];

        // SE REALIZA LA PETICION AL ENDPOINT DE AUTENTICACION DE USUARIO
        axios.post('api/v1/authenticate', user).then((res) => {

          // SE OBTIENE EL TOKEN DE ACCESO DE MANERA SEGURA
          const accessToken = _.get(res, 'data.accessToken');

          // SE OBTIENE EL TOKEN DE ACTUALIZACION DE MANERA SEGURA
          const refreshToken = _.get(res, 'data.refreshToken');

          // SE OBTIENE EL MENSAJE
          const message = _.get(res, 'data.message');

          // SE ESTABLECE UNA SESION ACTIVA
          ctx.dispatch('authenticate', { accessToken, refreshToken }).then(() => {

            // SE COMPRUEBA QUE SI EXISTE ALGUN MENSAJE JUNTO CON LA PETICION
            if (message) {

              // SE MUESTA EL MENSAJE
              instance.$dialog({
                title: 'Advertencia',
                text: message,
              });
            }

            // SE RESUELVE LA PROMESA Y SE RETORNA EL MENSAJE RECIBIDO
            resolve(message);
          }).catch((err) => {

            // SE RECHAZA LA PROMESA Y SE RETORNA EL ERROR
            reject(err);
          })
        }).catch((err) => {

          // SE OBTIENEN EL ERROR DE MANERA SEGURA
          let msg = _.get(err, 'response.data.message', err.toString());

          // SE INACTIVA LA SESION
          ctx.commit('setLogout');

          // SE RECHAZA LA PROMESA Y SE RETORNA EL MENSAJE DE ERROR
          reject(msg);
        });
      });
    },
    /**
     * TERMINA LA SESION DE UN USUARIO
     */
    logout: (ctx) => {

      // SE RETORNA UNA NUEVA PROMESA
      return new Promise(resolve => {

        // SE ELIMINA EL TOKEN BEARER DE LA CONFIGURACION DE AXIOS
        delete axios.defaults.headers.common['Authorization'];

        // SE INACTIVA LA SESION
        ctx.commit('setLogout');

        // SE RESUELVE LA PROMESA
        resolve();
      });
    },
    /**
     * REALIZA EL REGISTRO DE UN NUEVO USUARIO
     */
    signup: (ctx, account) => {
      return new Promise((resolve, reject) => {
        axios.post('api/v1/register', account).then((res) => {
          const success = _.get(res, 'data.success', false);
          
          if (success) {

            resolve();

          } else {

            const message = _.get(res, 'data.message', 'Hubo un error al crear la cuenta del usuario');

            reject(message);
          }

        }).catch((err) => {
          // SE OBTIENEN EL ERROR DE MANERA SEGURA
          let msg = _.get(err, 'response.data.message', err.toString());

          reject(msg);
        })
      });
    }
  },
}