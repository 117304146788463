<template>
  <v-card color="transparent" flat>

    <v-card-title v-if="label">
      <div class="text-h4 o">{{ label }}</div>

      <v-spacer></v-spacer>

      <slot name="append-header" :is-loading="internalLoading" v-if="!$vuetify.breakpoint.mdAndDown"></slot>
    </v-card-title>

    <v-card-subtitle>{{ $t('Ngrid.startResult') + ` (${(items.length || 0)}) ` + $t('Ngrid.endResult')
      }}</v-card-subtitle>

    <v-card-text v-if="$vuetify.breakpoint.mdAndDown">
      <slot name="append-header" :is-loading="internalLoading"></slot>
    </v-card-text>

    <v-card-text>
      <template>
        <v-row>
          <v-col v-for="item in items" :key="item.name" cols="12" :sm="colSm" :md="colMd" :lg="colLg" :xl="colXl">
            <slot name="item" :item="item" :on="{
              click: () => $emit('click', item)
            }
              "></slot>
          </v-col>
        </v-row>
      </template>

      <infinite-loading ref="infiniteLoading" @infinite="infiniteHandler" spinner="bubbles"
        class="pt-4"></infinite-loading>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import InfiniteLoading from 'vue-infinite-loading';

export default {
  components: {
    InfiniteLoading
  },
  props: {
    src: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: null
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 28
    },
    filters: {
      type: Object,
      default: () => ({})
    },
    filterOperators: {
      type: Object,
      default: () => ({})
    },
    sortBy: {
      type: String,
      default: 'desc'
    },
    orderBy: {
      type: String,
      default: null
    },
    colSm: {
      type: [String, Number],
      default: 6
    },
    colMd: {
      type: [String, Number],
      default: 4
    },
    colLg: {
      type: [String, Number],
      default: 4
    },
    colXl: {
      type: [String, Number],
      default: 3
    },
    value: {},
  },
  watch: {
    src: {
      immediate: false,
      handler() {
        console.log('cambio source')
        this.reset();
      },
    },
    sortBy: {
      immediate: false,
      handler() {
        console.log('cambio sortBy')
        this.reset();
      },
    },
    filters: {
      inmediate: false,
      handler(newVal, oldVal) {

        if(!_.isEqual(newVal, oldVal)){
          console.log('se detecto cambio')

          clearTimeout(this.typingTimer)

          this.typingTimer = setTimeout(() => {
            this.reset();
          }, 1000);
        }
      },
      deep: true
    }
  },
  data() {
    return {
      page: 1,
      items: [],
      internalLoading: true,
      typingTimer: null
    };
  },
  methods: {
    reset() {
      const infiniteLoading = this.$refs.infiniteLoading

      infiniteLoading.stateChanger && infiniteLoading.stateChanger.complete();

      this.items = [];
      this.page = 1;

      infiniteLoading.stateChanger && infiniteLoading.stateChanger.reset();

      this.$emit('reset');
    },
    infiniteHandler($state) {
      this.internalLoading = true;

      axios
        .get(this.getUrl(this.src))
        .then((res) => {
          const items = _.get(res, "data.data", []);

          if (items.length) {
            this.page += 1;

            this.items.push(...items);

            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(() => {

          $state.error();

        }).finally(() => {
          this.internalLoading = false;
        });
    },
    getUrl(u) {
      // VERIFICA QUE LA URL TENGA PARAMETROS PREDEFINIDOS
      let arr = u.split("?");
      let temp = "";
      let filterParameters = this.getFilterParameters();

      if (arr.length > 1) {
        // CONCATENA LOS PARAMETROS DE PAGINACION A LOS PARAMETROS PREDEFINIDOS
        temp = `${arr[0]}?${arr[1]}&offset=${this.items.length}&limit=${this.itemsPerPage}&sort_by=${(this.orderBy || '')}&order_by=${this.sortBy || ''}&${filterParameters}`;
      } else {
        // UNICAMENTE ENVIA PARAMETROS DE PAGINACION
        temp = `${arr[0]}?offset=${this.items.length}&limit=${this.itemsPerPage}&sort_by=${(this.orderBy || '')}&order_by=${this.sortBy || ''}&${filterParameters}`;
      }

      return temp;
    },
    getFilterParameters() {
      let params = [];

      const filters = this.filters;
      const filterOperators = this.filterOperators;

      Object.keys(filters).map((i) => {
        if (
          filters[i] != undefined &&
          filters[i] != null &&
          filters[i] != ""
        ) {
          let op = "";

          if (
            filterOperators[i] != undefined &&
            filterOperators[i] != null &&
            filterOperators[i] != ""
          ) {
            op = filterOperators[i];
          }

          if (op == "") {
            params.push(i + "=" + filters[i]);
          } else {
            params.push(i + "=" + op + ":" + filters[i]);
          }
        }
      });

      return params.join("&");
    }
  }
};
</script>