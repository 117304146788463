import NAddress from './NAddress';
import NAppointment from './NAppointment';
import NAppointmentDialog from './NAppointmentDialog';
import NBottomSheet from './NBottomSheet';
import NChoice from './NChoice';
import NDatePicker from './NDatePicker';
import NField from './NField';
import NForm from './NForm';
import NGrid from './NGrid';
import NTextarea from './NTextarea';
import NTimePicker from './NTimePicker';
import NValidatable from './NValidatable';
import DialogPlugin from './dialog-plugin';
import NotificationPlugin from './notification-plugin';

export default {
  install(Vue) {
    Vue.component('n-address', NAddress);
    Vue.component('n-appointment', NAppointment);
    Vue.component('n-appointment-dialog', NAppointmentDialog);
    Vue.component('n-bottom-sheet', NBottomSheet);
    Vue.component('n-choice', NChoice);
    Vue.component('n-date-picker', NDatePicker);
    Vue.component('n-field', NField);
    Vue.component('n-form', NForm);
    Vue.component('n-grid', NGrid);
    Vue.component('n-textarea', NTextarea);
    Vue.component('n-time-picker', NTimePicker);
    Vue.component('n-validatable', NValidatable);

    Vue.use(DialogPlugin);
    Vue.use(NotificationPlugin);
  }
}