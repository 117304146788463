'use strict';

import NNotificationPlugin from './NNotificationPlugin';

export default {
    install(Vue){
        Vue.prototype.$notify = function({ icon, text, color, dismissible, duration }){

            if(this.notifications == undefined){
                this.notifications = [];
            }

            let index =_.findIndex(this.notifications, function(n){
                return n == null || n == undefined;
            });

            if(index == -1){
                index = this.notifications.length
            }

            let position = 60 * index;

            return new Promise((resolve)=>{
                
                let ComponentClass = Vue.extend(NNotificationPlugin);

                let instance = new ComponentClass({
                    propsData: {
                        icon,
                        text,
                        color,
                        dismissible,
                        position
                    }
                });

                let comp = instance.$mount();

                this.$el.append(instance.$el);
        
                this.notifications[index] = text;

                setTimeout(() => {
                    this.$el.removeChild(comp.$el);

                    this.notifications[index] = null;

                    resolve(true);

                    comp.$destroy();

                }, duration || 6000);
        
                comp.$on("ok", ()=>{
                    
                    this.notifications[index] = null;

                    resolve(true);
                    
                    comp.$destroy();
                });
        
            });
        };
    }
}