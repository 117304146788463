'use strict';

import NDialogPlugin from './NDialogPlugin';

export default {
    install(Vue){
        Vue.prototype.$dialog = function(propsData, component){

            component = component || NDialogPlugin;

            let ComponentClass = Vue.extend(component);

            let instance = new ComponentClass({ propsData: propsData });
            
            let comp = instance.$mount();
            
            return new Promise((resolve, reject)=>{
                comp.$on("ok", (text)=>{
                    resolve(text);
                    comp.$destroy();
                });
                
                comp.$on("cancel", ()=>{
                    reject();
                    comp.$destroy();
                });
            });
        };
    }
}